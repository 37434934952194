

$font-family-sans-serif:      'Poppins', "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`

$font-sizes: () !default;

$font-sizes: map-merge(
                (
                        12: 12px,
                        14: 14px,
                        16: 16px,
                        18: 18px,
                        20: 20px,
                        24: 24px,
                        30: 30px,
                        36: 36px,
                        48: 48px,
                        60: 60px,
                        72: 72px
                ),
                $font-sizes
);


@each $prop, $abbrev in (font-size: t) {
  @each $size, $length in $font-sizes {
    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
    @if $size > 24 {
      @include media-breakpoint-down(lg){
        .#{$abbrev}-#{$size} { #{$prop}: 24px !important;}
      }
    }
  }
}

@each $prop, $abbrev in (line-height: leading) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
  }
}

@each $prop, $abbrev in (border-radius: br) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
  }
}

//@each $prop, $abbrev in (border-radius: bw) {
//  @each $size, $length in $spacers {
//    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
//  }
//}


// Color system

// UI Grays
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #626262 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #232323 !default;
$black:    #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
      (
              "100": $gray-100,
              "200": $gray-200,
              "300": $gray-300,
              "400": $gray-400,
              "500": $gray-500,
              "600": $gray-600,
              "700": $gray-700,
              "800": $gray-800,
              "900": $gray-900
      ),
      $grays
);

// Brand Colors
// Primary

$blu: #6492fb !default;
$blue-700: #394855 !default;
$blue-600: #003a66 !default;
$blue-500: #0065b2 !default;
$blue-300: #66bcff !default;
$blue-200: #9dadbc !default;
$blue-100: #edf5f8 !default;

$brown:  #896537 !default;
$brown-300: #c09867 !default;

// Accents/Secondary Colors

$red: #D40E1E !default;
$red-700: #D40E1E !default;
$grin: #7ffbdc !default;
$green-700: #7ffbdc !default;
$sand: #7ffbdc !default;


$colors: (
        "red": $red,
        "green": $grin,
        "green-700": $green-700,
        "sand": $sand,
        "gray":       $gray-600,
        "gray-dark":  $gray-800
) !default;

// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
      (
        "primary":    $blu,
        "secondary":  $grin,
        "success":    $green,
        "info":       $blue-300,
        "warning":    $sand,
        "danger":     #ed1c24,
        "light":      $blue-100,
        "dark":       $purple-700,
        "#{$brand_name}-primary": $blu,
        "#{$brand_name}-green": $grin,
      ),
      $colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900 !default;
$yiq-text-light:            $white !default;

@mixin text-color-variant($parent, $color) {
  #{$parent} {
    color: $color !important;
  }
}

@each $color, $value in $theme-colors {
  @include text-color-variant(".text-color-#{$color}", $value);
}
@mixin bg-color-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;
  }
}

@each $color, $value in $theme-colors {
  @include bg-color-variant(".bg-#{$color}", $value);
}
